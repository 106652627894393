<template>
  <div class="studyTwoStep">
    <div class="leftnav">
      <Step :active="7" :jumpStep="jumpStep" />
    </div>
    <main>
      <div class="boxLeft">
        <div class="background" v-if="type === 'all'">
          <img :src="details.wgjztpdz" alt="">
        </div>
        <div class="background" v-if="type === 'section'">
          <img :src="textlist[sectionIndex].wgjztpdz" alt="">
        </div>
      </div>
      <div class="right">
        <div class="title">
          <p></p>
          <p> 倒计时:&nbsp;<span>{{ time }}</span> <span>S</span> <i></i> </p>
        </div>
        <div class="box" id="box">
          <p>请同学们看着</p>
          <p>{{ '图谱背诵' + (type === 'all' ? '整篇文章' : sectionText) }}</p>
        </div>
        <div class="right-footer" id="footer">
          <div class="wait" v-if="wait">评分中...</div>
          <div class="luyin" id="luyin"  v-if="isPress"></div>
          <div class="lyafter" v-if="result[currentIndex] && !isPress && !wait">
            <div class="afterbox">
              <p>恭喜你，本次获得</p>
              <el-rate
                v-model="result[currentIndex].total_score"
                disabled
                show-score
                disabled-void-color="#D0D0D0"
                :colors="['#FEA20B', '#FEA20B', '#FEA20B']"
                score-template="{value}">
              </el-rate>
            </div>
            <div class="score" id="score">
              <div>
                <span id="standard_score">{{ '标准度：' + result[currentIndex].standard_score + '%' }}</span>
                <span id="accuracy_score">{{ '准确度：' + result[currentIndex].accuracy_score + '%' }}</span>
              </div>
              <div>
                <span id="fluency_score">{{ '流利度：' + result[currentIndex].fluency_score + '%' }}</span>
                <span id="integrity_score">{{ '完整度：' + result[currentIndex].integrity_score + '%' }}</span>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div @click="touchclick">{{ isPress ? '结束背诵' : '点击背诵' }}</div>
            <!-- 下一步，右箭头 -->
            <div v-if="type==='section' && currentIndex===0" @click="next">下一段</div>
            <div @click="submit" v-else>提交</div>
          </div>
        </div>
      </div>
    </main>
    <Header :header_data="header_data" :noClicking="true" />
  </div>
</template>
<script>
import IseRecorder from '@/assets/js/index'
import Header from "../components/zjfheader.vue";
import Step from "../components/step.vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { getCurrentInstance, onBeforeUnmount } from 'vue'
import { getEnglishText, getTextContent, saveMyBreakpoint, getMyBreakpoint } from '@/api/student'
import { useStore } from 'vuex'
import { ElMessage } from "element-plus"
export default {
  components: {
    Header,
    Step,
  },
  setup() {
    const store = useStore()
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties
    const data = reactive({
      header_data: [],
      details: { kwmc: '' }, // 课文详情
      textlist: [], // 全部句子
      type: '', // 全文背诵还是分段背诵
      time: 0, // 倒计时
      currentTime: 0, // 当前倒计时起点
      imgTimer: null, // 轮播图计时器
      numTimer: null, // 时间计时器
      isPress: false, // 是否正在录音
      result: [], // 结果
      currentIndex: 0,
      IseRecorder: null,
      sectionNumber: '', // 分段背诵时的分段点
      sectionIndex: 0, // 分段时的图片定位
      sectionText: '', // 分段时的文字
      jumpStep: true,
      wait: false,
      notes: {},
      studyTimer: null, 
      studyTime: 0
    })
    // 展示结果
    window.showResult = (res) => {
      data.result[data.currentIndex] = res
      data.wait = false
    }
    onMounted(() => {
      let params = {
        cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
        cjlx: 1,
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      getMyBreakpoint(params).then(ele => {
        if (ele.success) {
          let notes = ele.result.ddxx
          data.notes = JSON.parse(notes)
          data.notes.step = 'testStep'
          preserve()
          data.studyTimer = setInterval(() => {
            data.studyTime++
          }, 1000)
        } else {
          ElMessage({
            message: ele.resultDesc,
            type: 'warning'
          })
        }
      })
      data.jumpStep = !(that.$route.query.jumpStep === 'false')
      data.IseRecorder = new IseRecorder()
      data.audioVoice = localStorage.getItem(`sound_${store.state.userInfo.realname}`) || 'y'
      // 获取课文
      getEnglishText({ kwid: that.$route.query.kwid }).then(res => {
        if(res.success) {
          data.details = res.result
          data.header_data = [
            { title: "图谱英语 — ", path: "newEnglish" },
            { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
            { title: that.$route.query.dymc + " — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
            { title: res.result.kwmc, path: "" },
          ]
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
      // 获取课文内容
      getTextContent({ kwid: that.$route.query.kwid }).then(res => {
        if(res.success) {
          if(res.result.length) {
            data.textlist = res.result
            if ((timeChange(data.textlist[data.textlist.length - 1][data.audioVoice+'sypwzz']) - timeChange(data.textlist[0][data.audioVoice+'sypwzq'])) < 180) {
              // 全文背诵
              allRecite()
            } else {
              // 分段背诵
              sectionRecite()
            }
          }
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    })
    // 点击
    const touchclick = () => {
      if (data.isPress) {
        clearInterval(data.numTimer)
        data.time = data.currentTime
        data.isPress = false
        data.wait = true
        data.IseRecorder.stop()
        if (data.type === 'section') {
          imgStop(data.sectionIndex >= data.sectionNumber ? data.sectionNumber : 0)
        }
      } else {
        data.time = data.currentTime
        data.numTimer = setInterval(() => {
          data.time--
          if (data.time === 0) {
            touchclick()
          }
        }, 1000)
        data.wait = false
        data.isPress = true
        data.IseRecorder.start()
        if (data.type === 'section') {
          imgRun(data.sectionIndex === data.sectionNumber ? data.sectionNumber : 0)
        }
      }
    }
    // 提交
    const submit = () => {
      if (data.isPress) {
        return false
      }
      if (data.wait) {
        return false
      }
      if (data.result[data.currentIndex]) {
        data.notes.kwcssc+=data.studyTime*1000
        data.notes.cscj = parseInt(data.result[data.currentIndex].total_score*20)
        if (data.notes.cscj < 80) {
          data.notes.xj = 0
        } else if (data.notes.cscj >= 80 && data.notes.cscj < 90) {
          data.notes.xj = 1
        } else if (data.notes.cscj >= 90 && data.notes.cscj < 100) {
          data.notes.xj = 2
        } else {
          data.notes.xj = 3
        }
        let params = {
          cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
          cjlx: 1,
          ddxx: JSON.stringify(data.notes),
          ddzt: 1,
          dyid: that.$route.query.dyid,
          xsid: store.state.userInfo.yhid,
          zid: that.$route.query.zid,
          zsdid: sessionStorage.getItem('zsdid')
        }
        saveMyBreakpoint(params).then(res => {
          console.log('学习记录保存成功')
          that.$router.replace({ name: 'result', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid} })
        })
      } else {
        ElMessage({
          message: '还未获得分数无法提交哦~',
          type: 'warning'
        })
      }
    }
    // 时间转换秒数
    const timeChange = (time) => {
      return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]) + Number(time.split(':')[2]) / 1000
    }
    // 全文背诵
    const allRecite = () => {
      data.type = 'all'
      data.currentTime = parseInt((timeChange(data.textlist[data.textlist.length - 1][data.audioVoice+'sypwzz']) - timeChange(data.textlist[0][data.audioVoice+'sypwzq'])) + 150)
      data.time = data.currentTime
      let article = ''
      data.textlist.forEach(ele => {
        article+=ele.jznr
      })
      sessionStorage.setItem('audioText', article)
    }
    // 分段背诵
    const sectionRecite = () => {
      data.type = 'section'
      let seconds = 180 // 多少秒一段 // 临时
      let num = -1
      let arr = []
      data.textlist.forEach(ele => {
        if (ele.sfds) {
          num++
          arr[num] = { list: [], time: 0 }
        }
        arr[num].list.push(ele)
      })
      arr.forEach(ele => {
        ele.time = timeChange(ele.list[ele.list.length-1][data.audioVoice+'sypwzz']) - timeChange(ele.list[0][data.audioVoice+'sypwzq'])
      })
      let arrIndex = null
      let time = 0
      for (let i = 0;i < arr.length; i++) {
        time+=arr[i].time
        if (time > seconds) {
          arrIndex = i-1
          break
        } else {
          arrIndex = i
        }
      }
      let number = 0
      arr.forEach((ele, index) => {
        if (arrIndex >= index) {
          number+=ele.list.length
        }
      })
      let audioText = ''
      for (let i = 1; i <= number; i++) {
        audioText+=data.textlist[i-1].jznr
      }
      sessionStorage.setItem('audioText', audioText)
      data.sectionNumber = number
      data.sectionText = '前' + number + '句'
      data.currentTime = parseInt((timeChange(data.textlist[data.sectionNumber-1][data.audioVoice+'sypwzz']) - timeChange(data.textlist[0][data.audioVoice+'sypwzq'])) + 150)
      data.time = data.currentTime
    }
    //分段背诵的时候，左侧图片的轮播效果
    const imgRun = (index) => {
      let time = timeChange(data.textlist[index][data.audioVoice+'sypwzz']) - timeChange(data.textlist[index][data.audioVoice+'sypwzq'])
      clearTimeout(data.imgTimer)
      data.sectionIndex = index
      data.imgTimer = setTimeout(() => {
        if (index+1 === data.sectionNumber) {
          imgStop(0)
        } else if (index === data.textlist.length-1) {
          imgStop(data.sectionNumber)
        } else {
          imgRun(index+1)
        }
      }, time*1000)
    }
    // 分段背诵的时候，左侧图片的轮播效果停止
    const imgStop = (index) => {
      clearTimeout(data.imgTimer)
      data.sectionIndex = index
    }
    // 下一段
    const next = () => {
      if (data.isPress) {
        return false
      }
      if (data.wait) {
        return false
      }
      if (data.result[data.currentIndex]) {
        data.currentIndex++
        data.sectionIndex = data.sectionNumber
        data.sectionText = '后' + (data.textlist.length - data.sectionNumber) + '句'
        let audioText = ''
        for (let i = data.sectionNumber; i < data.textlist.length; i++) {
          audioText+=data.textlist[i].jznr
        }
        sessionStorage.setItem('audioText', audioText)
        data.currentTime = parseInt((timeChange(data.textlist[data.textlist.length-1][data.audioVoice+'sypwzz']) - timeChange(data.textlist[data.sectionNumber][data.audioVoice+'sypwzq'])) + 150)
        data.time = data.currentTime
      } else {
        ElMessage({
          message: '还没有分数无法进行下一段哦~',
          type: 'warning'
        })
      }
    }
    // 销毁钩子
    onBeforeUnmount(() => {
      if (data.numTimer) {
        clearInterval(data.numTimer)
      }
      if (data.imgTimer) {
        clearTimeout(data.imgTimer)
      }
      if (data.studyTimer) {
        clearTimeout(data.studyTimer)
      }
    })
    // 保存进度
    const preserve = () => {
      let params = {
        cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
        cjlx: 1,
        ddxx: JSON.stringify(data.notes),
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      saveMyBreakpoint(params).then(res => {
        console.log('学习记录保存成功')
      })
    }
    return {
      ...toRefs(data),
      timeChange,
      allRecite,
      sectionRecite,
      touchclick,
      showResult,
      submit,
      imgRun,
      imgStop,
      next,
      onBeforeUnmount,
      preserve
    };
  },
};
</script>
<style lang="scss" scoped>
.studyTwoStep {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  .wait{
    font-size: 22px;
    text-align: center;
    padding-top: 50px;
  }
  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 9vw;
    z-index: 3;
  }
  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: .2vw .2vw .2vw 0;
    .boxLeft {
      width: 71.2vw;
      height: 100%;
      float: left;
      box-sizing: border-box;
      padding: 1vw 0 1vw 2vw;
      position: relative;
      .background {
        position: absolute;
        top: 1vw;
        left: 3.5vw;
        right: 0;
        bottom: 1vw;
        img {
          display: block;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .right {
      height: 100%;
      width: 23.3vw;
      border-radius: 3.125vw;
      box-sizing: border-box;
      padding-top: 1vw;
      position: relative;
      .title {
        width: 100%;
        text-align: center;
        font-size: 1.6vw;
        font-weight: 600;
        p:nth-child(2) {
          span {
            color: #FD706C;
          }
          span:nth-child(2) {
            color: #333;
            font-weight: 500;
            font-family: 'Times New Roman', Times, serif;
          }
        }
      }
      .box {
        width: 100%;
        position: absolute;
        top: 15%;
        bottom: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        font-size: 1.6vw;
        line-height: 2.5vw;
        font-weight: 600;
        p:nth-child(2) {
          // font-weight: 500;
        }
      }
      .right-footer {
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        .luyin {
          width: 7.708vw;
          height: 5vw;
          background: url('../../../assets/student/gifluyin.gif') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 33%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .lyafter{
          .afterbox {
            padding-top: 1vw;
            display: flex;
            justify-content: center;
            p {
              font-size: 1.3vw;
              float: left;
            }
            :deep(.el-rate) {
              float: left;
            }
            :deep(.el-rate__icon) {
              font-size: 1.7vw;
              margin: 0;
            }
            :deep(.el-rate__text) {
              display: none;
            }
          }
          .score {
            margin-top: 3%;
            display: flex;
            flex-direction: column;
            div {
              text-align: center;
              span {
                margin-right: 2%;
              }
              span:nth-child(2) {
                margin: 0;
              }
            }
          }
        }
        .text {
          padding-top: 5%;
          text-align: center;
          p {
            font-size: 1.2vw;
          }
        }
        .bottom {
          position: absolute;
          bottom: 5%;
          left: 50%;
          transform: translateX(-50%);
          width: 92%;
          display: flex;
          justify-content: space-around;
          div:nth-child(1) {
            width: 12.34vw;
            height: 3.75vw;
            background: url('../../../assets/student/startluyin.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 1.3vw;
            box-sizing: border-box;
            padding-right: 2vw;
            text-align: right;
            line-height: 3.75vw;
            cursor: pointer;
          }
          div:nth-child(2) {
            width: 6.25vw;
            height: 3.33333vw;
            background: url('../../../assets/student/tjbtn.png') no-repeat;
            background-size: 100% 100%;
            margin-top: .2vw;
            font-size: 1.4vw;
            text-align: center;
            line-height: 3.333vw;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>